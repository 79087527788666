import $ from "jquery";
import { logFactory, isCompExists } from "GlobalSite";

const compName = "comp_aem_fraud-alert";
const Log = logFactory(compName);
const { log, error } = Log;

$(function domReady() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  const $btn = $(discloseBtn);
  const contentId = $btn.attr("aria-controls");
  const $content = contentId ? $(`#${contentId}`) : null;

  if (!$content) {
    error("missing relation between aria-controls and content");
    return;
  }

  function toggle() {
    const isExpanded = $btn.attr("aria-expanded");
    if (isExpanded === "true") {
      $content.hide();
      $btn.attr("aria-expanded", false);
    } else {
      $btn.attr("aria-expanded", true);
      $content.show();
    }
  }

  $btn.on("click", toggle);
});
